import request from '@/utils/request'

// 获取云端上传临时上传密钥
export function generateKeys(data) {
  return request({
    url: '/common/upload-file/generate-temp-keys',
    method: 'POST',
    data
  })
}

// 文件删除
export function deleteFile(data) {
  return request({
    url: '/common/upload-file/delete',
    method: 'post',
    data
  })
}

// 查看/下载文件
export function uploaddownloadfile(data) {
  return request({
    url: '/common/upload-file/download-file',
    method: 'post',
    data
  })
}

// 获取当前管理员的头像等欢迎信息
export function getuserinfo(params) {
  return request({
    url: '/common/auth/user-info',
    method: 'get',
    params
  })
}

// 本地文件上传
export function upload(data) {
  return request({
    url: '/common/upload-file/upload',
    method: 'POST',
    data
  })
}